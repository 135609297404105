import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const bioQuery = graphql`
  query getBioQuery {
    allContentfulAsset {
      edges {
        node {
          title
          file {
            url
          }
        }
      }
    }
    allContentfulBio {
      nodes {
        text {
          json
        }
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <span>
        {children}
        <br />
      </span>
    ),
    [INLINES.ASSET_HYPERLINK]: node => {
      return (
        <a
          href={node.data.target.fields.file["en-US"].url}
          style={{ textDecoration: "none" }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

class BioPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <StaticQuery
        query={bioQuery}
        render={data => {
          const image = data.allContentfulAsset.edges.filter(edge => {
            return edge.node.title === "Björn, vit bakgrund"
          })[0].node

          const bio = data.allContentfulBio.nodes[0].text.json

          return (
            <Layout>
              <SEO
                title="Biografi"
                keywords={[
                  `Björn Wessman`,
                  `Biografi`,
                  `Galleri`,
                  `CV`,
                  `Information`,
                ]}
              />
              <div className={"row"}>
                <div className={"eight columns"}>
                  {documentToReactComponents(bio, options)}
                </div>
                <div className={"four columns"}>
                  <img
                    style={{ width: "100%" }}
                    src={image.file.url}
                    alt={image.title}
                  />
                </div>
              </div>
            </Layout>
          )
        }}
      />
    )
  }
}

export default BioPage
